import axios from "axios";





 //export const local_api =process.env.REACT_APP_API_URL;

 export const local_api = "https://padelove.store/api"



const api = axios.create({
  baseURL: local_api, 
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const response = await axios.post(`${local_api}/refresh-token`, {}, {
          withCredentials: true, 
        });

        const { accessToken } = response.data;

        localStorage.setItem('token', accessToken);
        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        return api(originalRequest);
      } catch (refreshError) {
     
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login?session=expired'; 
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);


export default api;
